export const FETCH_OFFERS_REQUEST = 'FETCH_OFFERS_REQUEST';
export const FETCH_OFFERS_SUCCESS = 'FETCH_OFFERS_SUCCESS';
export const FETCH_OFFERS_FAILURE = 'FETCH_OFFERS_FAILURE';

interface FetchOffersRequestAction {
  type: typeof FETCH_OFFERS_REQUEST;
}

interface FetchOffersSuccessAction {
  type: typeof FETCH_OFFERS_SUCCESS;
  payload: {
    data: any[];
    meta: { total: number; limit: number; page: number };
  };
}

interface FetchOffersFailureAction {
  type: typeof FETCH_OFFERS_FAILURE;
  payload: string;
}

export type OfferActionTypes =
  | FetchOffersRequestAction
  | FetchOffersSuccessAction
  | FetchOffersFailureAction;
