import React, { useState } from "react";
import { Table, Tooltip, Modal, Button } from "antd";
import { UpOutlined, DownOutlined } from "@ant-design/icons";
import "tailwindcss/tailwind.css";
import "../../../components/pages/controlPanel/OpenEstilos.css";
import FinalistInfo from "../../../components/pages/controlPanel/FinalistCandidates"; // Asegúrate de importar correctamente el componente

interface FinalistData {
  key: string;
  nombreBusqueda: string;
  inscritos: number;
  finalistas: number;
  fechaApertura: string;
  fechaCierre: string;
  diasActivos: number;
  promedioInscritos: number;
  imageUrl: string; // Nueva propiedad
  nombre: string; // Nueva propiedad
  puesto: string; // Nueva propiedad
  ubicacion: string; // Nueva propiedad
  habilidades: string[]; // Nueva propiedad
  descripcion: string; // Nueva propiedad
  radarData: any[]; // Nueva propiedad
  isProfileUnlocked: boolean; // Nueva propiedad
}

const data: FinalistData[] = [
  {
    key: "1",
    nombreBusqueda: "Supermercado",
    inscritos: 50,
    finalistas: 2,
    fechaApertura: "24/02/2024",
    fechaCierre: "24/02/2024",
    diasActivos: 5,
    promedioInscritos: 10,
    imageUrl: "https://randomuser.me/api/portraits/men/1.jpg",
    nombre: "Juan Pérez",
    puesto: "Gerente",
    ubicacion: "Madrid, España",
    habilidades: ["Liderazgo", "Comunicación"],
    descripcion: "Soy un entusiasta del mundo digital con una pasión por transformar datos en decisiones estratégicas. Con más de 5 años de experiencia en análisis de datos y machine learning, he colaborado en proyectos innovadores que han optimizado",
    radarData: [
      { subject: 'Comunicación', A: 120, B: 110 },
      { subject: 'Trabajo en equipo', A: 98, B: 130 },
      { subject: 'Liderazgo', A: 86, B: 130 },
      { subject: 'Creatividad', A: 99, B: 100 },
      { subject: 'Adaptabilidad', A: 85, B: 90 },
    ],
    isProfileUnlocked: true,
  },
  {
    key: "2",
    nombreBusqueda: "Almacén",
    inscritos: 40,
    finalistas: 3,
    fechaApertura: "24/02/2024",
    fechaCierre: "24/02/2024",
    diasActivos: 6,
    promedioInscritos: 6,
    imageUrl: "https://randomuser.me/api/portraits/men/2.jpg",
    nombre: "Carlos López",
    puesto: "Supervisor",
    ubicacion: "Barcelona, España",
    habilidades: ["Organización", "Gestión"],
    descripcion: "Soy un entusiasta del mundo digital con una pasión por transformar datos en decisiones estratégicas. Con más de 5 años de experiencia en análisis de datos y machine learning, he colaborado en proyectos innovadores que han optimizado",
    radarData: [
      { subject: 'Comunicación', A: 120, B: 110 },
      { subject: 'Trabajo en equipo', A: 98, B: 130 },
      { subject: 'Liderazgo', A: 86, B: 130 },
      { subject: 'Creatividad', A: 99, B: 100 },
      { subject: 'Adaptabilidad', A: 85, B: 90 },
    ],
    isProfileUnlocked: true,
  },
];

const CompletedFinalists: React.FC = () => {
  const [sortOrder, setSortOrder] = useState<"ascend" | "descend" | null>(null);
  const [selectedFinalistData, setSelectedFinalistData] = useState<FinalistData | null>(null);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleSort = (columnKey: string) => {
    setSortOrder(sortOrder === "ascend" ? "descend" : "ascend");
  };

  const showModal = (finalistData: FinalistData) => {
    setSelectedFinalistData(finalistData);
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setSelectedFinalistData(null);
  };

  const columns = [
    {
      title: (
        <div onClick={() => handleSort("nombreBusqueda")} className="flex items-center cursor-pointer">
          <h1 className="font-bold text-caption">
            Nombre de búsqueda {sortOrder === "ascend" ? <UpOutlined className="ml-1" /> : <DownOutlined className="ml-1" />}
          </h1>
        </div>
      ),
      dataIndex: "nombreBusqueda",
      key: "nombreBusqueda",
      render: (text: string) => (
        <Tooltip title={text}>
          <div className="text-body-sm font-bold text-blue3">{text}</div>
        </Tooltip>
      ),
    },
    {
      title: "Inscritos",
      dataIndex: "inscritos",
      key: "inscritos",
      render: (text: number) => <div className="text-body-sm font-bold text-[#5B5B5B]">{text}</div>,
    },
    {
      title: "Finalistas",
      dataIndex: "finalistas",
      key: "finalistas",
      render: (text: number, record: FinalistData) => (
        <Button onClick={() => showModal(record)} type="link">
          {text}
        </Button>
      ),
    },
    {
      title: "Fecha de apertura",
      dataIndex: "fechaApertura",
      key: "fechaApertura",
      render: (text: string) => <div className="text-body-sm font-bold text-[#5B5B5B]">{text}</div>,
    },
    {
      title: "Fecha de cierre",
      dataIndex: "fechaCierre",
      key: "fechaCierre",
      render: (text: string) => <div className="text-body-sm font-bold text-[#5B5B5B]">{text}</div>,
    },
    {
      title: "Días activos de búsqueda",
      dataIndex: "diasActivos",
      key: "diasActivos",
      render: (text: number) => <div className="text-body-sm font-bold text-[#5B5B5B]">{text}</div>,
    },
    {
      title: "Promedio de Inscritos al día",
      dataIndex: "promedioInscritos",
      key: "promedioInscritos",
      render: (text: number) => <div className="text-body-sm font-bold text-[#5B5B5B]">{text}</div>,
    },
    {
      title: "", // Sin título para esta columna
      key: "actions",
      render: () => (
        <div
          style={{
            fontSize: "24px",
            color: "#006497",
            fontWeight: "bold",
            textAlign: "center",
            background: "transparent", // Fondo transparente solo para los puntos
          }}
        >
          ...
        </div>
      ),
    },
  ];

  return (
    <div className="px-[15px]">
      <h3 className="font-bold text-lg pb-[24px]">Finalistas Completados</h3>
      <div style={{ maxHeight: "200px", overflowY: "auto" }}>
        <Table
          columns={columns}
          dataSource={data}
          pagination={false}
          rowClassName={(record, index) => (index % 2 === 0 ? "bg-[#F2F2F2]" : "bg-[#E6E6E6]")}
          scroll={{ y: 200 }}
          className="custom-table"
        />
      </div>

      {/* Modal for displaying FinalistInfo */}
      <Modal
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        width={800} // Ajusta el tamaño del modal si es necesario
        closeIcon={<button className="bg-blue3 text-white rounded-full p-2 w-6 h-6 flex items-center justify-center">X</button>} // Custom close button
      >
        {selectedFinalistData && (
          <>
            <h2 className="text-center font-bold text-[22px] mb-2">Candidatos finalistas</h2>
            <p className="text-center text-gray-500 mb-4">Te presentamos a los finalistas de tu proceso de selección:</p>
            <FinalistInfo
              imageUrl={selectedFinalistData.imageUrl}
              nombre={selectedFinalistData.nombre}
              puesto={selectedFinalistData.puesto}
              ubicacion={selectedFinalistData.ubicacion}
              habilidades={selectedFinalistData.habilidades}
              descripcion={selectedFinalistData.descripcion}
              radarData={selectedFinalistData.radarData}
              isProfileUnlocked={selectedFinalistData.isProfileUnlocked}
            />
          </>
        )}
      </Modal>
    </div>
  );
};

export default CompletedFinalists;
