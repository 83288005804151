import { ThunkAction } from 'redux-thunk';
import { RootState } from '../../../../store/store';
import FetchWithIP from '../../../utils/FetchHeaders';
import {
  GET_COMPANY_RATINGS_REQUEST,
  GET_COMPANY_RATINGS_SUCCESS,
  GET_COMPANY_RATINGS_FAILURE,
  GetCompanyRatingsActionTypes,
  CompanyRating
} from '../../../../../constants/pages/company/rating/GetRatingCompany';

export const getCompanyRatingsRequest = (): GetCompanyRatingsActionTypes => ({
  type: GET_COMPANY_RATINGS_REQUEST
});

export const getCompanyRatingsSuccess = (data: CompanyRating[]): GetCompanyRatingsActionTypes => ({
  type: GET_COMPANY_RATINGS_SUCCESS,
  payload: data
});

export const getCompanyRatingsFailure = (error: string): GetCompanyRatingsActionTypes => ({
  type: GET_COMPANY_RATINGS_FAILURE,
  payload: error
});

export const GetCompanyRatingsReducer = (
  empresaId: number
): ThunkAction<Promise<void>, RootState, unknown, GetCompanyRatingsActionTypes> => async (dispatch) => {
  dispatch(getCompanyRatingsRequest());

  try {
    const response = await FetchWithIP(`empresas/findAllValoracionesDetails/${empresaId}`, {
      method: 'GET',
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const responseData = await response.json();

    // Asumiendo que responseData.data es un array de CompanyRating
    if (responseData && responseData.data) {
      dispatch(getCompanyRatingsSuccess(responseData.data));
    } else {
      throw new Error('Formato de datos inesperado');
    }
  } catch (error: any) {
    dispatch(getCompanyRatingsFailure(error.message || 'Ha ocurrido un error al obtener las valoraciones de la empresa'));
  }
};