import React, { useState, useEffect } from "react";
import { Modal, Button, Input, message } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../../redux/store/store';
import { UploadPortfolioReducer } from '../../../../redux/actions/pages/myPortal/portfolio/PostPortfolio';
import { GetUserPortfolioReducer } from '../../../../redux/actions/pages/myPortal/portfolio/GetPortfolio';
import IconClosed from "../../../../assets/icons/IconClosed.svg";
import InputC from "../../../../components/pages/offers/KillerQuestions/ComponentsKillersQ/Input";
import ModalAddMultimedia from "./ModalAddMultimedia";
import ModalSaved from "../../myPortal/Modals/ModalSavedChanges";

interface ModalAddPortfolioProps {
  visible: boolean;
  onClose: () => void;
}

const ModalAddPortfolio: React.FC<ModalAddPortfolioProps> = ({
  visible,
  onClose,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [url, setUrl] = useState("");
  const [isAddMultimediaModalVisible, setIsAddMultimediaModalVisible] = useState(false);
  const [multimediaFile, setMultimediaFile] = useState<File | null>(null);
  const [multimediaFileName, setMultimediaFileName] = useState("");
  const [isSecondModalVisible, setIsSecondModalVisible] = useState(false);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);

  const resetForm = () => {
    setTitle("");
    setDescription("");
    setUrl("");
    setMultimediaFile(null);
    setMultimediaFileName("");
    setIsSubmitDisabled(true);
  };

  useEffect(() => {
    const isValid = title.length > 0 && description.length > 0 && multimediaFile !== null;
    setIsSubmitDisabled(!isValid);
  }, [title, description, multimediaFile]);

  useEffect(() => {
    if (visible) {
      resetForm();
    }
  }, [visible]);

  const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => setTitle(e.target.value);
  const handleDescriptionChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => setDescription(e.target.value);
  const handleUrlChange = (e: React.ChangeEvent<HTMLInputElement>) => setUrl(e.target.value);

  const handleAddMultimedia = () => {
    setIsAddMultimediaModalVisible(true);
  };

  const handleMultimediaSave = (file: File, name: string) => {
    setMultimediaFile(file);
    setMultimediaFileName(name);
    setIsAddMultimediaModalVisible(false);
  };

  const handleSaveChanges = async () => {
    if (multimediaFile) {
      try {
        const formData = new FormData();
        formData.append('titulo', title);
        formData.append('nombre', multimediaFileName);
        formData.append('descripcion', description);
        formData.append('url', url);
        formData.append('portafolioFile', multimediaFile);

        console.log('FormData contents:');
        formData.forEach((value, key) => {
          console.log(key, value);
        });

        const response = await dispatch(UploadPortfolioReducer(formData));
        
        if (response.error) {
          throw new Error(response.error.message || 'Error desconocido al guardar el portfolio');
        }
        
        await dispatch(GetUserPortfolioReducer());
        setIsSecondModalVisible(true);
      } catch (error) {
        console.error('Error al guardar el portfolio:', error);
        message.error('Error al guardar el portfolio: ' + (error instanceof Error ? error.message : 'Error desconocido'));
      }
    } else {
      message.error('Por favor, añade un archivo multimedia');
    }
  };

  const handleModalSavedClose = () => {
    setIsSecondModalVisible(false);
    onClose();
  };

  return (
    <>
      <Modal
        open={visible && !isAddMultimediaModalVisible && !isSecondModalVisible}
        onCancel={onClose}
        footer={null}
        centered
        closable={false}
        width={677}
        bodyStyle={{ borderRadius: "12px" }}
        style={{
          borderRadius: "12px",
          border: "1px solid #E1E1E2",
          marginTop: "15px",
          marginBottom: "15px",
        }}
      >
        <img
          src={IconClosed}
          alt="Cerrar"
          onClick={onClose}
          style={{
            position: "absolute",
            top: "34px",
            right: "34px",
            cursor: "pointer",
            width: "24px",
            height: "24px",
          }}
        />

        <div className="text-center mx-[86px] mb-[46px] mt-[21px]">
          <h3 className="mt-[16px] mx-[44px] text-heading-md font-bold">
            Añadir proyecto
          </h3>
          <p className="font-medium px-[55px] text-body-sm mt-[10px]">
            Por favor, a continuación edita tu información
          </p>
        </div>

        <div className="mx-[68px] mt-[43px]">
          <span className="text-body-md font-normal text-[#5F5F5F]">
            Título
          </span>
          <InputC
            placeholder=""
            value={title}
            onChange={handleTitleChange}
            style={{
              marginBottom: "23px",
              borderRadius: "12px",
              height: "36px",
              marginTop: "10px",
            }}
          />

          <span className="text-body-md text-[#757575] font-normal">
            Descripción del proyecto
          </span>
          <Input.TextArea
            rows={5}
            value={description}
            onChange={handleDescriptionChange}
            className="w-full 
                border
                my-[8px]
                border-[#D9D9D9] 
                placeholder:text-green32 
                focus:placeholder:text-grays 
                hover:placeholder:text-black 
                hover:bg-gray3 
                hover:border-2 
                hover:border-[#D9D9D9]
                hover:text-black 
                focus:border-4 
                focus:border-[#91c3fd] 
                focus:text-[#757575]
                rounded-[8px] 
                transition-all 
                duration-200 
                text-[#757575]
                font-normal
                text-body-md"
            style={{ height: 80 }}
          />

          <div className="flex items-center justify-between mb-[24px] mt-[15px]">
            <span className="text-body-md font-normal text-[#5F5F5F]">
              Archivos adjuntos
            </span>
            <Button
              className="text-blue3 font-semibold border border-white w-[218px] h-[36px] principal-nav-notify-buttonS flex items-center justify-center"
              onClick={handleAddMultimedia}
            >
              <PlusOutlined />
              Añadir Contenido multimedia
            </Button>
          </div>

          {multimediaFile && (
            <div className="mt-[16px] mb-[24px]">
              <span className="text-body-md font-normal text-[#5F5F5F]">
                Archivo seleccionado: {multimediaFileName}
              </span>
            </div>
          )}

          <span className="text-body-md font-normal text-[#5F5F5F]">
            Enlace (Opcional)
          </span>
          <InputC
            placeholder=""
            value={url}
            onChange={handleUrlChange}
            style={{
              borderRadius: "12px",
              height: "36px",
              marginTop: "10px",
            }}
          />
        </div>

        <div className="flex mx-[86px] justify-center mt-[34px]">
          <Button
            onClick={onClose}
            className="principal-nav-notify-buttonG w-[118px] h-[44px]"
            style={{ marginRight: "8px", borderRadius: "4px" }}
          >
            Cancelar
          </Button>
          <Button
            disabled={isSubmitDisabled}
            onClick={handleSaveChanges}
            className={`w-[181px] h-[44px] ml-[18px] rounded-[4px] 
              ${isSubmitDisabled
                ? 'bg-[#F4F4F5] text-[#757575] font-semibold text-body-md cursor-not-allowed'
                : 'bg-blue3 principal-nav-notify-button2 text-white cursor-pointer'
              }
            `}
          >
            Guardar
          </Button>
        </div>
      </Modal>

      <ModalAddMultimedia
        visible={isAddMultimediaModalVisible}
        onClose={() => setIsAddMultimediaModalVisible(false)}
        onSave={handleMultimediaSave}
      />

      <ModalSaved
        visible={isSecondModalVisible}
        onClose={handleModalSavedClose}
      />
    </>
  );
};

export default ModalAddPortfolio;