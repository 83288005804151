// src/actions/pages/myPortal/portfolio/uploadPortfolioActions.ts

import { ThunkAction } from 'redux-thunk';
import { RootState } from '../../../../store/store';
import {
  UPLOAD_PORTFOLIO_REQUEST,
  UPLOAD_PORTFOLIO_SUCCESS,
  UPLOAD_PORTFOLIO_FAILURE,
  UploadPortfolioActionTypes,
} from '../../../../../constants/pages/myPortal/portfolio/PostPortfolio';
import FetchWithIP from '../../../utils/FetchHeaders';

export const UploadPortfolioReducer = (formData: FormData): ThunkAction<Promise<any>, RootState, unknown, UploadPortfolioActionTypes> => async (dispatch) => {
  dispatch({ type: UPLOAD_PORTFOLIO_REQUEST });

  try {
    console.log('Enviando FormData al servidor:');
    formData.forEach((value, key) => {
      console.log(key, value);
    });

    const response = await FetchWithIP('portafolios-usuarios/uploadPortafolio', {
      method: 'POST'
    }, formData);

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || `Error HTTP: ${response.status}`);
    }

    const responseData = await response.json();
    console.log('Respuesta del servidor:', responseData);
    dispatch({ type: UPLOAD_PORTFOLIO_SUCCESS, payload: responseData });
    return responseData;
  } catch (error: any) {
    console.error('Error en UploadPortfolioReducer:', error);
    dispatch({ type: UPLOAD_PORTFOLIO_FAILURE, payload: error.message });
    throw error;
  }
};