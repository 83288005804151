import getIp from './GetIp';

const API_URL = process.env.REACT_APP_API_BASE_URL;

interface FetchOptions extends RequestInit {
  headers?: HeadersInit;
}

async function FetchWithIP(endpoint: string, options: FetchOptions = {}, data?: any): Promise<any> {
  const url = `${API_URL}${endpoint}`;
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  console.log(timeZone , " zona horaria")
  const headers: HeadersInit = {
      'Authorization': `Bearer ${localStorage.getItem("token")}`,
      'Timezone': timeZone, 
      
  };
  
  if (options.method !== 'GET') {
      const ip = await getIp();
      headers['ip'] = ip;
  }

  let body: any;
  if (data instanceof FormData) {
      body = data;
  } else if (data && options.method !== 'GET') {
      headers['Content-Type'] = 'application/json';
      body = JSON.stringify(data);
  }

  const response = await fetch(url, {
      method: options.method || "POST",
       headers,
       body,

  });

  return response;
}
export default FetchWithIP;