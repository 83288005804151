import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { RootState } from '../../../store/store';
import {
  FETCH_OFFERS_REQUEST,
  FETCH_OFFERS_SUCCESS,
  FETCH_OFFERS_FAILURE,
  OfferActionTypes
} from '../../../../constants/company/Offers';
import FetchWithIP from '../../utils/FetchHeaders';

// Action Creators
export const fetchOffersRequestReducer = (): OfferActionTypes => ({
  type: FETCH_OFFERS_REQUEST,
});

export const fetchOffersSuccessReducer = (
  data: any[], 
  meta: { total: number; limit: number; page: number }
): OfferActionTypes => ({
  type: FETCH_OFFERS_SUCCESS,
  payload: { data, meta },
});

export const fetchOffersFailureReducer = (error: string): OfferActionTypes => ({
  type: FETCH_OFFERS_FAILURE,
  payload: error,
});

export const GetClosedOffersReducer = (
  page: number = 1, 
  limit: number = 10
): ThunkAction<Promise<void>, RootState, unknown, Action<string>> => 
  async (dispatch) => {

    dispatch(fetchOffersRequestReducer());

    try {
      // Realiza la petición sin incluir el empresaId
      const response = await FetchWithIP(`ofertas/findAllStateFinalizadoToken?page=${page}&limit=${limit}`, {
        method: 'GET',
      });

      if (!response.ok) {
        throw new Error(`Error HTTP: ${response.status}`);
      }

      const responseData = await response.json();

      if (Array.isArray(responseData.data)) {
        const { total, limit, page } = responseData.meta;
        dispatch(fetchOffersSuccessReducer(responseData.data, { total, limit, page }));
      } else {
        throw new Error('Los datos obtenidos no son un array');
      }

    } catch (error) {
      dispatch(fetchOffersFailureReducer('Error al obtener las ofertas finalizadas'));
    }
  };
