import React from 'react';
import { Card, Typography } from 'antd';
import CheckboxC from "../../components/checkbox/CheckboxProps";  
import IconEdit from "../../assets/icons/EditP.svg";
import "tailwindcss/tailwind.css";

const API_BASE_URL_EXACT = process.env.REACT_APP_API_BASE_URL_EXACT;
const { Title, Text } = Typography;

interface CardPortfolioProps {
  icon?: React.ReactNode;      
  projectName: string;         
  projectDescription: string;
  projectDetails: string;
  skills: string;              
  website: string;
  showCheckbox?: boolean;
  showEditIcon?: boolean;
  onEditClick?: () => void;
  isSelected?: boolean;
  onSelect?: () => void;
  portafolio: string;
}

const CardPortfolio: React.FC<CardPortfolioProps> = ({
  icon = <CheckboxC />,  
  projectName,
  projectDescription,
  projectDetails,
  skills,
  website,
  showCheckbox = true,
  showEditIcon = false,
  onEditClick,
  isSelected = false,
  onSelect,
  portafolio
}) => {
  return (
    <>
      <Card
        className="w-[338px] h-[350px] p-6 shadow-xl rounded-none transition-shadow hover:shadow-2xl relative" // Aseguramos que los hijos puedan ser posicionados de forma relativa
        bordered={false}
        bodyStyle={{ padding: 0 }}
      >
        <div className="flex items-start mb-3 mt-3">
          {showCheckbox && (
            <div className="mr-2" onClick={onSelect}>
              <CheckboxC checked={isSelected} />
            </div>
          )}
          <div>
            <Title level={4} className="-mt-5 font-bold text-[16px]">
              {projectName}
            </Title>
            <Text className="block text-[#52525B] font-bold">
              {projectDescription}
            </Text>
            <Text className="block text-[14px] text-[#52525B] font-medium">
              {projectDetails}
            </Text>
            <Text className="block text-[#52525B] font-bold mb-4">
              {skills}
            </Text>
            <img 
              src={API_BASE_URL_EXACT+portafolio} 
              style={{
                width: "150px",
                height: "150px"
              }}
            />
            <a
              href={website}
              className="text-[#52525B] font-medium hover:underline"
              target="_blank"
              rel="noopener noreferrer"
              style={{
                position: "absolute",
                bottom: "15px"
              }}
            >
              {website}
            </a>
          </div>
          {showEditIcon && (  // Condiciona el renderizado del icono de edición
            <img
              src={IconEdit}
              alt="Edit"
              className="absolute top-3 right-3 w-6 h-6 cursor-pointer"  // Posiciona el icono en la esquina superior derecha
              onClick={onEditClick}  // Ejecuta la función onEditClick cuando se haga clic
            />
          )}
        </div>
      </Card>
      <style>{`
        .ant-card:not(.ant-card-bordered) {
          box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 10px 20px rgba(0, 0, 0, 0.2); /* Similar a la sombra de CustomRadio */
          transition: box-shadow 0.3s ease-in-out;
        }
        .ant-card:hover:not(.ant-card-bordered) {
          box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2), 0 14px 28px rgba(0, 0, 0, 0.25); /* Sombra más intensa al hacer hover */
        }
      `}</style>
    </>
  );
};

export default CardPortfolio;
