import React, { useState, useEffect } from "react";
import { Modal, Button, Input } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import IconClosed from "../../../../assets/icons/IconClosed.svg";
import InputC from "../../../../components/pages/offers/KillerQuestions/ComponentsKillersQ/Input";
import SelectBox from "../SelectBox";
import CustomTag from "../../offers/CreateOffers/CustomTag";
import ModalAddSkills from "./ModalAddSkills";
import ModalSaved from "../../myPortal/Modals/ModalSavedChanges";
import ModalDeletePortfolio from "./ModalDeletePortfolio";
import ModalAddMultimedia from "./ModalAddMultimedia";
import ModalAddPortfolio from "./ModalAddPortfolio";
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../../redux/store/store';
import { GetUserPortfolioReducer } from '../../../../redux/actions/pages/myPortal/portfolio/GetPortfolio';
import { GetSoftSkillsReducer } from "../../../../redux/actions/common/softSkills/SoftSkills";
import { DeletePortfolioReducer } from '../../../../redux/actions/pages/myPortal/portfolio/DeletePortfolio';

interface ModalEditPortfolioProps {
  visible: boolean;
  onClose: () => void;
  skills: string[];
  projectId: number | null;
}

const ModalEditPortfolio: React.FC<ModalEditPortfolioProps> = ({
  visible,
  onClose,
  skills: initialSkills = [],
  projectId
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const { rex_userPortfolio, rex_loading, rex_error } = useSelector((state: RootState) => state.getPortfolio);
  const { rex_softSkills } = useSelector((state: RootState) => state.softSkills);
  const { rex_deleting, rex_deleteError } = useSelector((state: RootState) => state.deletePortfolio);

  const [projectName, setProjectName] = useState("");
  const [jobTitle, setJobTitle] = useState("");
  const [sector, setSector] = useState("");
  const [workLocation, setWorkLocation] = useState("");
  const [comment, setComment] = useState("");
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [skills, setSkills] = useState<string[]>(initialSkills);
  const [attachedFiles, setAttachedFiles] = useState<File[]>([]); // Estado para archivos adjuntos
  const [searchTerm, setSearchTerm] = useState('');
  const [typingTimeout, setTypingTimeout] = useState<number | undefined>(undefined);
  const [selectedSkills, setSelectedSkills] = useState<string[]>([]);

  const [isSecondModalVisible, setIsSecondModalVisible] = useState(false);
  const [isDeleteSkillModalVisible, setIsDeleteSkillModalVisible] =
    useState(false);
  const [isAddWorkModalVisible, setIsAddWorkModalVisible] = useState(false);
  const [isAddSkillModalVisible, setIsAddSkillModalVisible] = useState(false);
  const [isAddMultimediaModalVisible, setIsAddMultimediaModalVisible] =
    useState(false);
  
    const handleAddSkills = (newSkills: string[]) => {
      setSkills(prevSkills => {
        const uniqueNewSkills = newSkills.filter(skill => !prevSkills.includes(skill));
        return [...prevSkills, ...uniqueNewSkills];
      });
      setIsSubmitDisabled(false);
      handleAddSkillModalClose();
    };
    const handleDeletePortfolio = async () => {
      if (projectId) {
        const result = await dispatch(DeletePortfolioReducer(projectId));
        if (result.success) {
          // Portfolio deleted successfully
          onClose(); // Close the modal
          dispatch(GetUserPortfolioReducer()); // Refresh the portfolio list
        } else {
          // Handle error (you might want to show an error message to the user)
          console.error("Failed to delete portfolio:", result.error);
        }
      }
    };

  
  const handleJobTitleChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setJobTitle(e.target.value);
  const handleCommentChange = (e: React.ChangeEvent<HTMLTextAreaElement>) =>
    setComment(e.target.value);

  useEffect(() => {
    setSkills(initialSkills || []);
  }, [initialSkills]);



  useEffect(() => {
    if (projectId && rex_userPortfolio) {
      const selectedProject = rex_userPortfolio.find(project => project.id === projectId);
      if (selectedProject) {
        setProjectName(selectedProject.nombre || "");
        // Aquí puedes establecer otros estados con los datos del proyecto
        // Por ejemplo:
        // setJobTitle(selectedProject.jobTitle || "");
        // setComment(selectedProject.description || "");
        // etc.
      }
    }
  }, [projectId, rex_userPortfolio]);

  const handleProjectNameChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setProjectName(e.target.value);


  const handleSaveChanges = () => {
    setIsSecondModalVisible(true);
  };

  const handleAddWork = () => {
    setIsAddWorkModalVisible(true);
  };


  const handleAddSkillModalClose = () => {
    setIsAddSkillModalVisible(false);
  };

  const handleAddMultimedia = () => {
    setIsAddMultimediaModalVisible(true);
  };

  const handleSaveFile = (file: File) => {
    setAttachedFiles([...attachedFiles, file]); // Agrega el archivo al estado
    setIsAddMultimediaModalVisible(false); // Cierra el modal de multimedia
  };

  const handleSkillSelect = (value: string) => {
    if (!selectedSkills.includes(value)) {
      setSelectedSkills((prevSkills) => [...prevSkills, value]);
    }
  };

  const handleSkillRemove = (skill: string) => {
    setSelectedSkills((prevSkills) => prevSkills.filter((item) => item !== skill));
  };

  const handleSearchSkills = (search: string) => {
    setSearchTerm(search);
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }

    const timeoutId = window.setTimeout(async () => {
      await dispatch(GetSoftSkillsReducer(search, 1, 5));
    }, 500);

    setTypingTimeout(timeoutId);
  };

  return (
    <>
      <Modal
        open={
          visible && !isAddSkillModalVisible && !isAddMultimediaModalVisible
        }
        onCancel={onClose}
        footer={null}
        centered
        closable={false}
        width={677}
        bodyStyle={{ borderRadius: "12px" }}
        style={{
          borderRadius: "12px",
          border: "1px solid #E1E1E2",
          marginTop: "15px",
          marginBottom: "15px",
        }}
      >
        <img
          src={IconClosed}
          alt="Cerrar"
          onClick={onClose}
          style={{
            position: "absolute",
            top: "34px",
            right: "34px",
            cursor: "pointer",
            width: "24px",
            height: "24px",
          }}
        />

        <div className="text-center mx-[86px] mb-[46px] mt-[21px]">
          <h3 className="mt-[16px] mx-[44px] text-heading-md font-bold">
            Editar proyecto
          </h3>
          <p className="font-medium px-[55px] text-body-sm mt-[10px]">
            Por favor, a continuación edita tu información
          </p>
        </div>

        <div className="mx-[68px] mt-[43px]">
          <span className="text-body-md font-normal text-[#5F5F5F]">
            Título
          </span>
          <InputC
            placeholder="Nombre del proyecto"
            value={projectName}
            onChange={handleProjectNameChange}
            style={{
              marginBottom: "23px",
              borderRadius: "12px",
              height: "36px",
              marginTop: "10px",
            }}
          />

          <span className="text-body-md text-[#757575] font-normal">
            Descripción del proyecto
          </span>
          <Input.TextArea
            rows={5}
            value={comment}
            onChange={handleCommentChange}
            className="w-full 
                border
                my-[8px]
                border-[#D9D9D9] 
                placeholder:text-green32 
                focus:placeholder:text-grays 
                hover:placeholder:text-black 
                hover:bg-gray3 
                hover:border-2 
                hover:border-[#D9D9D9]
                hover:text-black 
                focus:border-4 
                focus:border-[#91c3fd] 
                focus:text-[#757575]
                rounded-[8px] 
                transition-all 
                duration-200 
                text-[#757575]
                font-normal
                text-body-md"
            style={{ height: 80 }}
          />

          <div className="flex items-center justify-between mt-[27px]">
            <span className="text-body-md font-normal text-[#5F5F5F]">
              Habilidades (Opcional)
            </span>
           {/*} <Button
              className="text-blue3 font-semibold border border-white w-[132px] h-[36px] principal-nav-notify-buttonS flex items-center justify-center"
              onClick={openAddSkillModal}
            >
              <PlusOutlined />
              Añadir habilidad
            </Button>*/}
          </div>

          {/*<InputC
            placeholder="Lorem ipsum"
            value={jobTitle}
            onChange={handleJobTitleChange}
            style={{
              marginBottom: "40px",
              borderRadius: "12px",
              height: "36px",
              marginTop: "12px",
            }}
          />*/}
           <SelectBox
            placeholder="Seleccionar habilidades"
            options={rex_softSkills.map((skill: { soft_skill: string }) => ({
              value: skill.soft_skill,
              label: skill.soft_skill,
            }))}
            onSearch={handleSearchSkills}
            onChange={(value) => handleSkillSelect(value)}
            className="Input-Filter-Employment w-full mt-[5px] rounded-[12px] custom-input-company px-1"
          />

          <div className="flex flex-wrap mt-4">
            {selectedSkills.map((skill) => (
              <CustomTag
                key={skill}
                text={skill}
                onClose={() => handleSkillRemove(skill)}
              />
            ))}
          </div>


          <div className="flex items-center justify-between mb-[24px] mt-[15px]">
            <span className="text-body-md font-normal text-[#5F5F5F]">
              Archivos adjuntos (Opcional)
            </span>
            <Button
              className="text-blue3 font-semibold border border-white w-[218px] h-[36px] principal-nav-notify-buttonS flex items-center justify-center"
              onClick={handleAddMultimedia}
            >
              <PlusOutlined />
              Añadir Contenido multimedia
            </Button>
          </div>

          {/* Mostrar las imágenes adjuntas aquí */}
          <div className="flex mt-[16px] justify-center">
            <div className="flex space-x-[12px] justify-center">
              {attachedFiles.map((file, index) => (
                <div
                  key={index}
                  className={`w-[100px] h-[100px] ${attachedFiles.length === 1 ? "mx-auto" : ""
                    }`}
                >
                  <img
                    src={URL.createObjectURL(file)}
                    alt={`Archivo adjunto ${index + 1}`}
                    className="w-full h-full object-cover rounded"
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="mx-[68px] mt-[20px]">
          <span className="text-body-md font-normal text-[#5F5F5F]">
            Enlace (Opcional)
          </span>
          <InputC
            placeholder="Nombre del proyecto"
            value={projectName}
            onChange={handleProjectNameChange}
            style={{
              marginBottom: "23px",
              borderRadius: "12px",
              height: "36px",
              marginTop: "10px",
            }}
          />
        </div>
        <div className="flex mx-[86px] justify-center mt-[34px]">
        <Button
            className="text-blue3 font-semibold border border-white w-[70px] h-[36px] principal-nav-notify-buttonS flex items-center justify-center"
            onClick={handleDeletePortfolio}
            loading={rex_deleting}
          >
            Eliminar
          </Button>
          <Button
            className="text-blue3 font-semibold border border-white w-[70px] h-[36px] principal-nav-notify-buttonS flex items-center justify-center"
            onClick={handleSaveChanges}
          >
            Guardar
          </Button>
        </div>

        <div className="mx-[68px] mb-[44px]  mt-[19px]">
          <div className="flex justify-center">
            <Button
              className="text-blue3 font-semibold border border-white w-[296px] h-[36px] principal-nav-notify-buttonS flex items-center justify-center"
              onClick={handleAddWork}
            >
              <PlusOutlined />
              Añadir Proyecto
            </Button>
          </div>
        </div>
      </Modal>
      
      <ModalSaved
        visible={isSecondModalVisible}
        onClose={() => setIsSecondModalVisible(false)}
      />

<ModalAddSkills
        visible={isAddSkillModalVisible}
        onClose={handleAddSkillModalClose}
        onAdd={handleAddSkills}
        existingSkills={skills}
      />

      <ModalDeletePortfolio
        visible={isDeleteSkillModalVisible}
        onClose={() => setIsDeleteSkillModalVisible(false)}
        skillName={null} />

      <ModalAddPortfolio
        visible={isAddWorkModalVisible}
        onClose={() => setIsAddWorkModalVisible(false)}
        />

      <ModalAddMultimedia
        visible={isAddMultimediaModalVisible}
        onClose={() => setIsAddMultimediaModalVisible(false)}
        onSave={handleSaveFile} // Pasa la función para guardar archivos
      />
    </>
  );
};

export default ModalEditPortfolio;
