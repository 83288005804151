import {
  GET_COMPANY_RATINGS_REQUEST,
  GET_COMPANY_RATINGS_SUCCESS,
  GET_COMPANY_RATINGS_FAILURE,
  GetCompanyRatingsActionTypes,
  CompanyRating
} from '../../../../../constants/pages/company/rating/GetRatingCompany';
  
  interface GetCompanyRatingsState {
    rex_loading_companie: boolean;
    rex_companyRatings: CompanyRating[] | null;
    rex_error: string | null;
  }
  
  const initialState: GetCompanyRatingsState = {
    rex_loading_companie: false,
    rex_companyRatings: null,
    rex_error: null
  };
  
  const getCompanyRatingsReducer = (
    state = initialState,
    action: GetCompanyRatingsActionTypes
  ): GetCompanyRatingsState => {
    switch (action.type) {
      case GET_COMPANY_RATINGS_REQUEST:
        return {
          ...state,
          rex_loading_companie: true,
          rex_error: null
        };
      case GET_COMPANY_RATINGS_SUCCESS:
        return {
          ...state,
          rex_loading_companie: false,
          rex_companyRatings: action.payload,
          rex_error: null
        };
      case GET_COMPANY_RATINGS_FAILURE:
        return {
          ...state,
          rex_loading_companie: false,
          rex_error: action.payload,
          rex_companyRatings: null
        };
      default:
        return state;
    }
  };
  
  export default getCompanyRatingsReducer;
