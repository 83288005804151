import React, { useState, useEffect } from "react";
import { Modal, Button } from "antd";
import IconClosed from "../../../../assets/icons/IconClosed.svg";
import ModalSaved from "./ModalSavedChanges";
import CustomTag from "../../offers/CreateOffers/CustomTag";
import SelectBox from "../SelectBox";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../redux/store/store";
import { GetSoftSkillsReducer } from "../../../../redux/actions/common/softSkills/SoftSkills";
interface ModalAddSkillsProps {
  visible: boolean;
  onClose: () => void;
  onAdd: (newSkills: string[]) => void;  // Asegúrate de que esto acepte un array de strings
  existingSkills: string[];
}
const ModalAddSkills: React.FC<ModalAddSkillsProps> = ({ visible, onClose, onAdd }) => {
  const [selectedSkills, setSelectedSkills] = useState<string[]>([]);
  const [isModalSavedVisible, setIsModalSavedVisible] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [typingTimeout, setTypingTimeout] = useState<number | undefined>(undefined);
  const isSubmitDisabled = selectedSkills.length === 0;
  const dispatch = useDispatch<AppDispatch>();
  const { rex_softSkills } = useSelector((state: RootState) => state.softSkills);

  useEffect(() => {
    if (visible) {
      setSelectedSkills([]);
    }
  }, [visible]);

  const handleSaveSkill = () => {
    onAdd(selectedSkills);  // Esto pasa un array de strings
    onClose();
  };


  const handleModalSavedClose = () => {
    setIsModalSavedVisible(false);
    onClose();
  };

  const handleSearchSkills = (search: string) => {
    setSearchTerm(search);
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }

    const timeoutId = window.setTimeout(async () => {
      await dispatch(GetSoftSkillsReducer(search, 1, 5));
    }, 500);

    setTypingTimeout(timeoutId);
  };

  const handleSkillSelect = (value: string) => {
    if (!selectedSkills.includes(value)) {
      setSelectedSkills((prevSkills) => [...prevSkills, value]);
    }
  };

  const handleSkillRemove = (skill: string) => {
    setSelectedSkills((prevSkills) => prevSkills.filter((item) => item !== skill));
  };

  return (
    <>
      <Modal
        open={visible}
        onCancel={onClose}
        footer={null}
        centered
        closable={false}
        width={677}
        bodyStyle={{ borderRadius: "12px" }}
        style={{ borderRadius: "12px", border: "1px solid #E1E1E2", marginTop: "15px", marginBottom: "15px" }}
      >
        <img
          src={IconClosed}
          alt="Cerrar"
          onClick={onClose}
          style={{
            position: "absolute",
            top: "34px",
            right: "34px",
            cursor: "pointer",
            width: "24px",
            height: "24px",
          }}
        />

        <div className="text-center mx-[86px] mb-[36px] mt-[20px]">
          <h3 className="mt-[16px] mx-[44px] text-heading-md font-bold">
            Añadir habilidades
          </h3>
          <p className="font-medium px-[55px] text-body-sm mt-[10px]">
            Por favor, a continuación ingresa la habilidad que deseas añadir
          </p>
        </div>

        <div className="mx-[86px] mb-[32px] mt-[24px]">
          <span className="text-body-md text-[#757575] font-medium">
            Habilidad
          </span>

          <SelectBox
            placeholder="Seleccionar habilidades"
            options={rex_softSkills.map((skill: { soft_skill: string }) => ({
              value: skill.soft_skill,
              label: skill.soft_skill,
            }))}
            onSearch={handleSearchSkills}
            onChange={(value) => handleSkillSelect(value)}
            style={{ marginTop: "20px" }}
            className="Input-Filter-Employment w-full mt-[16px] rounded-[12px] custom-input-company px-1"
          />

          <div className="flex flex-wrap mt-4">
            {selectedSkills.map((skill) => (
              <CustomTag
                key={skill}
                text={skill}
                onClose={() => handleSkillRemove(skill)}
              />
            ))}
          </div>

          <div className="flex justify-center mt-[40px]">
            <Button
              onClick={onClose}
              className="principal-nav-notify-buttonG w-[118px] h-[44px]"
              style={{ marginRight: "8px", borderRadius: "4px" }}
            >
              Cancelar
            </Button>
            <Button
               disabled={selectedSkills.length === 0}
               onClick={handleSaveSkill}
              className={`w-[181px] h-[44px] ml-[18px] rounded-[4px] ${isSubmitDisabled ? "bg-[#F4F4F5] text-[#757575] font-semibold text-body-md cursor-not-allowed" : "bg-blue3 principal-nav-notify-button2 text-white cursor-pointer"}`}
            >
              Guardar cambios
            </Button>
          </div>
        </div>
      </Modal>

      <ModalSaved
        visible={isModalSavedVisible}
        onClose={handleModalSavedClose}
      />
    </>
  );
};

export default ModalAddSkills;
