export const GET_COMPANY_RATINGS_REQUEST = 'GET_COMPANY_RATINGS_REQUEST';
export const GET_COMPANY_RATINGS_SUCCESS = 'GET_COMPANY_RATINGS_SUCCESS';
export const GET_COMPANY_RATINGS_FAILURE = 'GET_COMPANY_RATINGS_FAILURE';

export interface CompanyRatingData {
  id: number;
  valoracion: string;
  observacion: string;
  createdAt: string;
  usuarios: {
    imagen: string | null;
    cargo: string;
    personas: {
      apellido_materno: string;
      apellido_paterno: string;
      nombre: string;
    };
  };
}

export interface CompanyRating {
  id: number;
  empresa: string;
  valoraciones_empresas: CompanyRatingData[];
}

export interface GetCompanyRatingsRequestAction {
  type: typeof GET_COMPANY_RATINGS_REQUEST;
}

export interface GetCompanyRatingsSuccessAction {
  type: typeof GET_COMPANY_RATINGS_SUCCESS;
  payload: CompanyRating[];
}

export interface GetCompanyRatingsFailureAction {
  type: typeof GET_COMPANY_RATINGS_FAILURE;
  payload: string;
}

export type GetCompanyRatingsActionTypes =
  | GetCompanyRatingsRequestAction
  | GetCompanyRatingsSuccessAction
  | GetCompanyRatingsFailureAction;