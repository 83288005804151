import React, { useState, useEffect} from "react";
import { Modal, Button } from "antd";
import IconClosed from "../../../../assets/icons/IconClosed.svg";
import IconDelete from '../../../../assets/icons/IconDelete.svg';
import { PlusOutlined } from "@ant-design/icons";
import ModalAddSkills from "./ModalAddSkills";
import ModalDeleteSkills from "./ModalDelete";
import ModalConfirm from "./ModalConfirm";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../redux/store/store";
import { CreateSoftSkillUsuarioReducer } from "../../../../redux/actions/pages/myPortal/softSkills/PostSoftSkills";
import { GetSoftSkillsReducer } from "../../../../redux/actions/pages/myPortal/softSkills/GetSoftSkills";

interface ModalEditSkillsProps {
  visible: boolean;
  onClose: () => void;
  skills: string[];
}

const ModalEditSkills: React.FC<ModalEditSkillsProps> = ({ visible, onClose, skills: initialSkills = [] }) => {
  const [skills, setSkills] = useState<string[]>(initialSkills);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [isAddSkillModalVisible, setIsAddSkillModalVisible] = useState(false);
  const [isDeleteSkillModalVisible, setIsDeleteSkillModalVisible] = useState(false);
  const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);
  const [skillToDelete, setSkillToDelete] = useState<string | null>(null);
  const dispatch = useDispatch<AppDispatch>();
  const { rex_softSkills } = useSelector((state: RootState) => state.softSkills);

  useEffect(() => {
    setSkills(initialSkills);
    setIsSubmitDisabled(initialSkills.length === 0);
  }, [initialSkills]);

  const openAddSkillModal = () => {
    setIsAddSkillModalVisible(true);
  };

  const handleAddSkillModalClose = () => {
    setIsAddSkillModalVisible(false);
  };

  const openDeleteSkillModal = (skill: string) => {
    setSkillToDelete(skill);
    setIsDeleteSkillModalVisible(true);
  };

  const handleDeleteSkillModalClose = () => {
    setIsDeleteSkillModalVisible(false);
    setSkillToDelete(null);
  };

  const handleDeleteSkill = () => {
    if (skillToDelete) {
      const updatedSkills = skills.filter(skill => skill !== skillToDelete);
      setSkills(updatedSkills);
      setIsSubmitDisabled(updatedSkills.length === 0);
      setIsDeleteSkillModalVisible(false);
      setIsConfirmModalVisible(true);
    }
  };

  const handleSaveChanges = async () => {
    if (!isSubmitDisabled) {
      const softSkillsData = {
        soft_skills: skills.map(skill => {
          const matchedSkill = rex_softSkills.find((s: any) => s.soft_skill === skill);
          return {
            id: matchedSkill?.id,
            soft_skill: skill, // Añadimos el campo `soft_skill`
            porcentaje: 50
          };
        }).filter(skill => skill.id !== undefined) // Filtramos los skills que no tengan id
      };
  
      try {
        const result = await dispatch(CreateSoftSkillUsuarioReducer(softSkillsData));
        if (!result.error) {
          await dispatch(GetSoftSkillsReducer());
          onClose();
        } else {
          console.error("Error saving skills:", result.error);
          // Agrega feedback de error para el usuario si lo necesitas
        }
      } catch (error) {
        console.error("Error saving skills:", error);
        // Agrega feedback de error para el usuario si lo necesitas
      }
    }
  };
  

  const handleAddSkill = (newSkills: string[]) => {
    setSkills(prevSkills => {
      const uniqueNewSkills = newSkills.filter(skill => !prevSkills.includes(skill));
      return [...prevSkills, ...uniqueNewSkills];
    });
    setIsSubmitDisabled(false);
    handleAddSkillModalClose();
  };

  return (
    <>
      <Modal
        open={visible && !isAddSkillModalVisible && !isDeleteSkillModalVisible && !isConfirmModalVisible}
        onCancel={onClose}
        footer={null}
        centered
        closable={false}
        width={677}
        bodyStyle={{ borderRadius: "12px" }}
        style={{ borderRadius: "12px", border: "1px solid #E1E1E2", marginTop: '15px', marginBottom: '15px' }}
      >
        <img
          src={IconClosed}
          alt="Cerrar"
          onClick={onClose}
          style={{
            position: "absolute",
            top: "34px",
            right: "34px",
            cursor: "pointer",
            width: "24px",
            height: "24px",
          }}
        />

        <div className="text-center mx-[86px] mb-[51px] mt-[21px]">
          <h3 className="mt-[16px] mx-[44px] text-heading-md font-bold">
            Editar Mis habilidades
          </h3>
          <p className="font-medium px-[55px] text-body-sm mt-[10px]">
            Por favor, a continuación edita tu información
          </p>
        </div>

        <div className="mx-[47px] mb-[32px]">
          <span className="text-body-md font-semibold">Habilidades</span>
          <ul className="my-[26px]">
            {skills.map((skill, index) => (
              <li key={index} className="flex justify-between items-center my-[26px]">
                <span className="text-body-md font-normal">{skill}</span>
                <img
                  src={IconDelete}
                  alt="Eliminar"
                  onClick={() => openDeleteSkillModal(skill)}
                  style={{
                    cursor: "pointer",
                    width: "24px",
                    height: "24px",
                  }}
                />
              </li>
            ))}
          </ul>
          <div className="flex justify-center">
            <Button className="text-blue3 font-semibold border border-white w-[232px] h-[36px] principal-nav-notify-buttonS flex items-center justify-center" onClick={openAddSkillModal}>
              <PlusOutlined />
              Añadir habilidad
            </Button>
          </div>
        </div>

        <div className="flex justify-center mt-[40px] mb-[35px]">
          <Button
            onClick={onClose}
            className="principal-nav-notify-buttonG w-[118px] h-[44px]"
            style={{ marginRight: "8px", borderRadius: "4px" }}
          >
            Cancelar
          </Button>
          <Button
            disabled={isSubmitDisabled}
            onClick={handleSaveChanges}
            className={`w-[181px] h-[44px] ml-[18px] rounded-[4px] ${isSubmitDisabled ? 'bg-[#F4F4F5] text-[#757575] font-semibold text-body-md cursor-not-allowed' : 'bg-blue3 principal-nav-notify-button2 text-white cursor-pointer'}`}
          >
            Guardar cambios
          </Button>
        </div>
      </Modal>

      <ModalAddSkills 
        visible={isAddSkillModalVisible} 
        onClose={() => setIsAddSkillModalVisible(false)}
        onAdd={handleAddSkill}
        existingSkills={skills}
      />

      <ModalDeleteSkills
        visible={isDeleteSkillModalVisible}
        onClose={handleDeleteSkillModalClose}
        skillName={skillToDelete}
        onConfirmDelete={handleDeleteSkill}
      />

      <ModalConfirm
        visible={isConfirmModalVisible}
        onClose={() => setIsConfirmModalVisible(false)}
        message={skillToDelete}
      />
    </>
  );
};

export default ModalEditSkills;