import React from "react";
import { Layout } from "antd";
import ButtonText from "../../button/ButtonText";
import "tailwindcss/tailwind.css";
import { useNavigate } from "react-router-dom";

const { Sider } = Layout;

interface SidebarOffersProps {
  inDrawer?: boolean;
  title?: string;
}

const SidebarOffers: React.FC<SidebarOffersProps> = ({
  inDrawer = false,
  title = "Tus ofertas de empleo",
}) => {
  const navigate = useNavigate();

  const buttonsOpenOffers = [
    {
      type: "link",
      label: "Reponedor de supermercado",
      size: "small",
      textColor: "#006497",
      color: "white",
    },
    {
      type: "link",
      label: "Mozo de almacén",
      size: "small",
      textColor: "#006497",
      color: "white",
    },
    {
      type: "link",
      label: "Administrativo",
      size: "small",
      textColor: "#006497",
      color: "white",
    },
  ];

  const buttonsClosedOffers = [
    {
      type: "link",
      label: "Reponedor de supermercado",
      size: "small",
      textColor: "#006497",
      color: "white",
    },
    {
      type: "link",
      label: "Mozo de almacén",
      size: "small",
      textColor: "#006497",
      color: "white",
    },
    {
      type: "link",
      label: "Administrativo",
      size: "small",
      textColor: "#006497",
      color: "white",
    },
  ];

  const buttonsSavedOffers = [
    {
      type: "link",
      label: "Cajero",
      size: "small",
      textColor: "#006497",
      color: "white",
    },
  ];

  const buttonsManageOffers = [
    {
      type: "link",
      label: "¿Pricing?",
      size: "small",
      textColor: "#006497",
      color: "white",
    },
    {
      type: "link",
      label: "¿Archivadas?",
      size: "small",
      textColor: "#006497",
      color: "white",
    },
    {
      type: "link",
      label: "¿Compartidas?",
      size: "small",
      textColor: "#006497",
      color: "white",
    },
  ];

  return (
    <div className="flex min-h-screen">
      <Sider
        width={227}
        style={{ backgroundColor: "#fff" }}
        // style={{ backgroundColor: "#FCFCFC" }}
        className={` ${inDrawer
            ? "p-[5px] border-none ml-[-15px] mt-[-15px]"
            : "p-[24px] rounded-lg shadow-lg border-2 border-sky-blue0"
          }`}
      >
        <h2 className="text-heading-md text-green42 font-bold mb-[26px]">
          {title}
        </h2>

        <div className="mb-6">
          <ButtonText
            buttons={[
              {
                type: "link",
                label: "Crear una nueva oferta de empleo",
                size: "small",
                textColor: "#006497",
                color: "white",
                fontWeight: "bold",
                fontSize: "14px",
                minHeight: "40px",
              },
            ]}
            vertical
            gap={4}
          />
        </div>

        <div className="mb-[24px]">
          <h3 className="font-bold text-green42 text-caption">
            Ofertas abiertas
          </h3>
          <ButtonText buttons={buttonsOpenOffers} vertical />
        </div>

        <div className="mb-[24px]">
          <h3 className="font-bold text-green42 text-caption">
            Ofertas finalizadas
          </h3>
          <ButtonText buttons={buttonsClosedOffers} vertical />
        </div>

        <div className="mb-[24px]">
          <h3 className="font-bold text-green42 text-caption">
            Ofertas guardadas
          </h3>
          <ButtonText buttons={buttonsSavedOffers} vertical />
        </div>

        <div className="mb-[24px]">
          <h3 className="font-bold text-green42 text-caption">
            Gestionar tus ofertas
          </h3>
          <ButtonText buttons={buttonsManageOffers} vertical />
        </div>

        <button
          className="principal-nav-notify-button  !h-[36px] !w-[100px] bg-white text-blue3 ml-4 py-2 px-4 rounded-md border-1 border-blue3 text-body-sm font-semibold transition duration-300"
          onClick={() => {
            localStorage.clear();
            navigate('/homeCompany');
          }}
        >
          Log out
        </button>

      </Sider>
    </div>
  );
};

export default SidebarOffers;
